// extracted by mini-css-extract-plugin
export var alignLeft = "t_qh d_fp d_bG d_dv";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignRight = "t_qj d_fr d_bH d_dx";
export var alignColumnLeft = "t_q7 d_fs d_bN d_dv";
export var alignColumnCenter = "t_q8 d_ft d_bP d_dw";
export var alignColumnRight = "t_q9 d_fv d_bQ d_dx";
export var milestonesContainer = "t_rC d_dW";
export var milestonesContainerFull = "t_rD d_dT";
export var milestonesSubtitle = "t_mf d_mf d_cs";
export var milestonesComponentWrapper = "t_mb d_mb d_cv";
export var compContentWrapper = "t_rd d_mc d_bz d_bJ d_bM d_bF d_c7 d_H";
export var milestonesMainHeader = "t_l8 d_l8 d_w d_cr";
export var milestonesSubHeader = "t_l9 d_l9 d_w";
export var milestonesComponentWrapperRow = "t_rF d_H";
export var milestonesBlockWrapper = "t_rG";
export var milestonesTextBlockWrapper = "t_rH";
export var milestonesComponentHeader = "t_md d_md d_w d_cr";
export var milestonesComponentParagraph = "t_mj d_mj d_w";
export var btnWrapper = "t_d2 d_bz d_ck";
export var btnWrapperCards = "t_rg d_bz d_cd";
export var imageWrapper = "t_rJ d_cs d_bz";
export var Title1Small = "t_rv x_rv x_rX x_rY";
export var Title1Normal = "t_rw x_rw x_rX x_rZ";
export var Title1Large = "t_rx x_rx x_rX x_r0";
export var Title2Small = "t_rK x_rK x_rX x_r1";
export var Title2Normal = "t_rL x_rL x_rX x_r2";
export var Title2Large = "t_rM x_rM x_rX x_r3";
export var SubtitleSmall = "t_qd x_qd x_rX x_r7";
export var SubtitleNormal = "t_qf x_qf x_rX x_r8";
export var SubtitleLarge = "t_qg x_qg x_rX x_r9";
export var Subtitle2Small = "t_rN x_rN x_rX x_sb";
export var Subtitle2Normal = "t_rP x_rP x_rX x_sc";
export var Subtitle2Large = "t_rQ x_rQ x_rX x_sd";
export var BodySmall = "t_ry x_ry x_rX x_sf";
export var BodyNormal = "t_rz x_rz x_rX x_sg";
export var BodyLarge = "t_rB x_rB x_rX x_sh";
export var exceptionLineHeight = "t_rR x_sp";